<!--  -->
<template>
    <div class='loginBox'
         v-loading="loading"
         element-loading-text="正在登陆中"
         element-loading-spinner="el-icon-loading"
         key=""
         element-loading-background="rgba(0, 0, 0, 0.8)">
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import loginApi from "@/api/login";

import {setToken} from "@/utils/auth";
import {Message} from 'element-ui'

export default {
    //import引入的组件需要注入到对象中才能使用
    data() {
        //这里存放数据
        return {
            loading: true, //转圈登录
            inactive: false, //是否激活状态
        };
    },

    computed: {},

    watch: {},
    //方法集合
    methods: {
        //登录主方法
        loginByCode: function (code, state) {
            console.log(code, state)
            if (state === 'dingtalk') {
                console.log("钉钉登录")
                this.dingLogin(code, state);
            } else if (state === 'wx') {
                console.log("微信登录")
                this.wxLogin(code, state)
            } else if (state === 'wxNotActivate') {
                console.log("微信激活")
                this.inactive = true;
                this.loading = false;
            }
        },
        //微信登录
        wxLogin(code, state) {
            loginApi.loginWx(code, state).then(res => this.loginSuccess(res.content))
                .catch(reason => {
                    console.log(reason)
                    location.href = `${location.origin}/#/login`;
                })
        },
        //钉钉登录
        dingLogin: function (code, state) {
            loginApi.loginDingTalk(code, state).then(res => this.loginSuccess(res.content))
                .catch(() => {
                    Message({message: "请重新登录", type: "error"});
                    setTimeout(() => location.href = `${location.origin}/#/login`, 1000)
                })
        },
        loginSuccess: function (token) {
            this.$store.commit("SET_TOKEN", token);
            setToken(token);
            this.getUserInfo();
        },
        getUserInfo() {
            this.$store
                .dispatch("GetUserInfo")
                .then(() => {
                    // 判断微信是否绑定
                    //登录成功跳转到首页
                    this.$router.push({path: "/dashboard"})
                })
                .catch(() => this.$router.push({path: "/login"}))
                .finally(() => this.loading = false);
        },
    },

    created() {
        this.loginByCode(this.$route.query.code, this.$route.query.state)
    },

    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类

.loginBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.wxImg {
    width: 200px;
}
</style>
